<template>
  <Post :column="column" :from-query="params" />
</template>

<script>
  import formItem from './mixins'
  import { getloadAd } from '@/api/operate'
  export default {
    mixins: [formItem],
    created() {
      if (this.$route.query.id) this.getloadAd()
    },
    methods: {
      async getloadAd() {
        const params = { id: this.$route.query.id }
        const data = await getloadAd(params)
        if (data.success) {
          this.params.day_start_time = data.data.extra_info.day_start_time
          this.params.day_end_time = data.data.extra_info.day_end_time
          for (let el of this.column) {
            if (el.prop == 'begin_time' || el.prop == 'end_time') {
              this.params[el.prop] = data.data[el.prop] * 1000
            } else if (el.prop == 'is_show') {
              this.params[el.prop] = data.data.close == 1 ? 1 : 0
            } else if (el.prop == 'type') {
              this.params[el.prop] = data.data.config.type
            } else if (el.prop == 'audit_config') {
              for (let key in data.data[el.prop]) {
                if (key == 'ac_op') {
                  this.params[key] = Number(data.data[el.prop][key])
                } else {
                  if (data.data[el.prop][key] === true) {
                    this.params.audit_config.push(key)
                  }
                }
              }
            } else {
              this.params[el.prop] = data.data[el.prop]
            }
          }
          let picArr = data.data.pic.split(',')
          if (picArr.length)
            picArr.forEach((item, index) => {
              this.params[this.imgKey[index]] = item || ''
            })
          if (data.data.videoList.length)
            data.data.videoList.forEach((item, index) => {
              this.params[this.videoKey[index]] = item.video_url || ''
              this.params[`${this.videoKey[index]}_id`] = item.video_aid || ''
            })
        } else {
          this.$message.error('查询失败')
        }
      },
    },
  }
</script>
